<template>
    <div class="g-wrapper paid">
		<button class="g-top" @click="toTop">
			<span class="g-top__text">Top</span>
            <span class="g-top__arrow">
                <i class="fa-solid fa-arrow-up-long"></i>
            </span>
		</button>
        <section class="g-main">
            <AllDataBlock></AllDataBlock>
        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import AllDataBlock from "../../components/content-reach/AllDataBlock.vue"

export default {
    data() {
        return {
        }
    },
    components: {
        AllDataBlock,
    },
    computed: {
        ...mapGetters("client", ["getClientSlug"]),
    },
    methods: {
        // handleScroll() {
        //     // Any code to be executed when the window is scrolled
        //     this.isUserScrolling = (window.scrollY > 0);
        //     if ( window.scrollY > 30 ) {
        //         document.querySelector(".g-wrapper").classList.add('has-scrolled');
        //     } else {
        //         document.querySelector(".g-wrapper").classList.remove('has-scrolled');
        //     }
        // },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    beforeUnmount() {
        // window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        // window.addEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss">
@import "./src/assets/scss/views/google.scss";
</style>
