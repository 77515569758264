<template>
    <div class="g-sticky">
        <header class="g-header">
            <h2>Content Reach</h2>
        </header>
        <div class="g-graph">
            <div class="g-graph__header">
                <p class="sessions"><span class="reach-dot reach-dot--current"></span>Current reach {{ numberWithCommas(globalTotalReach) }} <span class="reach-dot reach-dot--target reach-dot--left"></span>Target reach {{ numberWithCommas(globalTargetReach) }}</p>
            </div>
            <apexchart ref="mainChart" height="320" type="area" :options="mainChartOptions" :series="mainSeries"></apexchart>
        </div>
        <div class="g-table">
            <div class="g-table__header">
                <h3>All Data</h3>
                <router-link :to="{path: `/client/${getClientSlug}/content`}">
                    <span class="arrow-link">Go to contility</span>
                </router-link>
            </div>
            <table class="g-table__table" cellspacing="0">
                <thead>
                    <tr>
                        <th width="24.40%" scope="col">Content name</th>
                        <th width="17.11%" scope="col"><span class="reach-dot reach-dot--created"></span>Title created</th>
                        <th width="13.96%" scope="col"><span class="reach-dot reach-dot--assigned"></span>Assigned</th>
                        <th width="18.99%" scope="col"><span class="reach-dot reach-dot--pending"></span>Pending review</th>
                        <th width="14.47%" scope="col"><span class="reach-dot reach-dot--published"></span>Published</th>
                        <th width="11.07%" scope="col">Total</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <div class="g-table">
        <table class="g-table__table" cellspacing="0">
            <tbody>
				<tr v-for="(row) in tableRows" :key="row">
                    <th width="24.40%" scope="row"><div v-html="row[0]"></div></th>
                    <td width="17.11%"><div v-html="numberWithCommas(row[1])"></div></td>
                    <td width="13.96%"><div v-html="numberWithCommas(row[2])"></div></td>
                    <td width="18.99%"><div v-html="numberWithCommas(row[3])"></div></td>
                    <td width="14.47%"><div v-html="numberWithCommas(row[4])"></div></td>
                    <td width="11.07%"><div v-html="numberWithCommas(row[5])"></div></td>
				</tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import axios from 'axios';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            updatedGoogleData: '',
            tableRows: [],
            uniqueKeywords: [],
            globalTotalReach: 0,
            globalTargetReach: 0,
            currentHistoricReachDates: [],
            mainSeries: [],
            clientParam: '',
            mainChartOptions: {
                chart: {
                    id: 'mainChart',
                    type: 'area',
                    height: 320,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#B592F2', '#EA5E9F'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                legend: {
                    show: false,
                },
                yaxis: {
                    floating: true,
                    forceNiceScale: true,
                    // min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                        offsetX: 50,
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                  text: 'Loading...'
                }
            },
        }
    },
    computed: {
        googleData: {
            get() {
				      return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
        ...mapGetters("client", ["getClientContentTree", "getClientSlug"]),
    },
    watch:{
      'getClientContentTree.nodes': {
        deep: true,
          async handler() {
              console.log('allDataBlock has content tree data');
              this.init();
            }
        }
    },
    methods: {
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = (window.scrollY > 0);
            if ( window.scrollY > 30 ) {
              this.$refs.mainChart.updateOptions({
                chart: {
                  height: 175,
                },
              })
            } else {
              this.$refs.mainChart.updateOptions({
                chart: {
                  height: 320,
                },
              })
            }
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async init(){
          // window.addEventListener('scroll', this.handleScroll);

          if(!this.getClientContentTree.nodes){
            alert('Error: unable to get content tree nodes.');
          //  window.location.href = window.location.protocol + '//' + window.location.hostname;
          }

          let nodes = this.getClientContentTree.nodes

          // Get the list of unique keywords & historic reach
          let keywords = []
          // let reachLabels = []
          let currentHistoricReach = {}
          let currentHistoricPublishedReach = {}
          let currentHistoricReachClean = []
          let currentHistoricPublishedReachClean = []
          // let currentHistoricReachDates = []
          for (var i = 0; i < nodes.length; i++) {
            // Keywords
            let keyword = nodes[i].keyword
            if (keyword) {
              keywords.push(keyword);
              // De-dupe the array
              this.uniqueKeywords = keywords.filter(function (theKeyword, index) {
                return keywords.indexOf(theKeyword) === index;
              });
            }
            // Historic reach
            if (nodes[i].historicalAccumulativeReach && nodes[i].level === 1) {
              // console.log(nodes[i].historicalAccumulativeReach)
              for (var hr = 0; hr < nodes[i].historicalAccumulativeReach.length; hr++) {
                let reachDate = nodes[i].historicalAccumulativeReach[hr].timeStamp
                let dateString = reachDate.slice(0, 10); // get to single day
                let reachValue = nodes[i].historicalAccumulativeReach[hr].reach
                let reachPublishedValue = nodes[i].historicalAccumulativeReach[hr].publishedReach

                if (!isNaN(reachValue) && !isNaN(reachPublishedValue)) {
                  if (isNaN(currentHistoricReach[dateString])) {
                    currentHistoricReach[dateString] = reachValue;
                  } else {
                    currentHistoricReach[dateString] = currentHistoricReach[dateString] + reachValue;
                  }
                  if (isNaN(currentHistoricPublishedReach[dateString])) {
                    currentHistoricPublishedReach[dateString] = reachPublishedValue;
                  } else {
                    currentHistoricPublishedReach[dateString] = currentHistoricPublishedReach[dateString] + reachPublishedValue;
                  }
                }
              }
            }
          }
          // Make clean arrays of these and update the series
          for (const item in currentHistoricReach) {
            let thedate = new Date((item).slice(0, 10))
            let mon = thedate.toLocaleString('en-UK', {month: 'short'})
            let dd = thedate.getDate()
            let dateString = mon + ' ' + dd
            this.currentHistoricReachDates.push(dateString)
            currentHistoricReachClean.push(currentHistoricReach[item])
          }
          for (const item in currentHistoricPublishedReach) {
            currentHistoricPublishedReachClean.push(currentHistoricPublishedReach[item])
          }
          this.mainSeries = [{ name: 'Target Reach', data: currentHistoricReachClean },{ name: 'Current Reach', data: currentHistoricPublishedReachClean }];

          // For some reason updating the chart data doesn't work, it seems to be that the chart isn't initialised
          // putting it in this completely unnecessary .get delays it enough.
          // [TODO] solve this issue properly.
          const response = await axios.get(`/api/clients/googleData/${this.clientParam}`)
          console.log('allDataBlock googleData response', response);
          this.$refs.mainChart.updateOptions({
              xaxis: {
                categories: this.currentHistoricReachDates,
              },
          })

          /*
          * Loop through each of the keywords
          * - Go through each bit of content with that keyword
          * - then for each status work out the reach
          * - push those to the array
          */
          for (var j = 0; j < this.uniqueKeywords.length; j++) {
            let reachTotal = 0
            let reachCreated = 0
            let reachAssigned = 0
            let reachPending = 0
            let reachPubished = 0
            let keywordCurrent = this.uniqueKeywords[j]
            // Looping through the content
            for (var k = 0; k < nodes.length; k++) {
              // Get the variables
              let keywordNode = nodes[k].keyword
              let theStatus = nodes[k].status.toLowerCase()
              let reach = 0
              if (nodes[k].reach && keywordNode === keywordCurrent) {
                reach = parseInt(nodes[k].reach)
                // Always add to the total
                reachTotal = reachTotal + reach
                this.globalTargetReach = this.globalTargetReach + reach

                // Add the other statuses if applicable
                if (theStatus === 'title created') {
                  reachCreated = reachCreated + reach
                }
                if (theStatus === 'assigned') {
                  reachAssigned = reachAssigned + reach
                }
                if (theStatus === 'pending review') {
                  reachPending = reachPending + reach
                }
                if (theStatus === 'published') {
                  reachPubished = reachPubished + reach
                  this.globalTotalReach = this.globalTotalReach + reach
                }
              }
            }

            // Add the rows
            let row = [keywordCurrent, reachCreated, reachAssigned, reachPending, reachPubished, reachTotal]
            this.tableRows.push(row);
        
          }
          this.$store.commit('client/setHidePageLoader', true);
        } 
    },
    // beforeUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },
    async mounted() {
      try{
        const route = useRoute();
        this.clientParam = route.params.client;
        await this.$store.dispatch('client/fetchClientContentTree', this.clientParam);
      }catch(error){
        console.log('fetchClientContentTree from all data block catch error', error);
      }
    },
}


</script>
